import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
} from 'react'
import update from 'immutability-helper'
import equal from 'deep-equal'
import { LegacyTabs, Button, ButtonGroup, LegacyCard, Form, Layout, Page, Text } from "@shopify/polaris";
import { withFirebase } from '../../../../../providers/firebase'
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { navigate } from "gatsby"
import CartPreview from '../../cartPreview'
import '../../create.css'
import Constants from '../../../../../helpers/constants'
import createRule from '../../../../../helpers/createRule'
import { updateRule, deleteRule } from '../../../../../helpers/updateRule'
import CartOfferType from './cartOfferType'
import TopBarTab from '../topBarTab';
import ButtonsTab from './buttonsTab';
import BodyTab from '../bodyTab';
import getSymbolFromCurrency from 'currency-symbol-map';
import removeCartScript from '../../../../../helpers/removeCartScript';
import CreateOfferSteps from '../../createOfferSteps';
import PageHeader from '../../../../../components/pageHeader';
import '../../common.css';
// Source: https://stackoverflow.com/a/44134328


function CreateCart(props) {
  console.log('OLD CREATE CART')
  const { token, shop, location, firebase, host } = props

  const existingOffer = location && location.state && location.state.rule
  const rules = location && location.state && location.state.rules
  const isEditing = !!existingOffer
  const isCreating = !isEditing

  const initialEditingState = {
    id: existingOffer && existingOffer.id || null, // always null if not created
    offerType: existingOffer && existingOffer.offerType || 'Cart',
    name: existingOffer && existingOffer.name || '',
    introText: existingOffer && existingOffer.introText || '',
    introTextColor: existingOffer && existingOffer.introTextColor || '#000000',
    topBarBackgroundColor: existingOffer && existingOffer.topBarBackgroundColor || '#E7E7E7',
    popupBorderRadius: existingOffer && existingOffer.popupBorderRadius !== undefined ? existingOffer.popupBorderRadius : 8,
    title: existingOffer && existingOffer.title || '',
    titleColor: existingOffer && existingOffer.titleColor || '#000000',
    description: existingOffer && existingOffer.description || '',
    descriptionColor: existingOffer && existingOffer.descriptionColor || '#000000',
    cta: existingOffer && existingOffer.cta || '',
    discount: existingOffer && existingOffer.discount || {sym:'$',value:""},
    bodyBackgroundColor: existingOffer && existingOffer.bodyBackgroundColor || '#ffffff',
    ctaBackgroundColor: existingOffer && existingOffer.ctaBackgroundColor || '#0CA127',
    ctaButtonTextColor: existingOffer && existingOffer.ctaButtonTextColor || '#ffffff',
    buttonBorderRadius: existingOffer && existingOffer.buttonBorderRadius !== undefined ? existingOffer.buttonBorderRadius : 3,
    buttonHeight: existingOffer && existingOffer.buttonHeight !== undefined ? existingOffer.buttonHeight : 35,
    checkoutText: existingOffer && existingOffer.checkoutText || '',
    checkoutTextColor: existingOffer && existingOffer.checkoutTextColor || '#0CA127',
    product: existingOffer && existingOffer.product || null,
    variants: existingOffer && existingOffer.variants || null,
    featuredImage: existingOffer
      ? existingOffer?.featuredImage
        ? existingOffer?.featuredImage
        : (existingOffer?.product?.images && existingOffer?.product?.images[0]?.originalSrc)
          ? existingOffer?.product?.images[0]?.originalSrc
          : null
      : null,
    enabled: existingOffer && existingOffer.enabled || false,
    triggerEvent: existingOffer && existingOffer.triggerEvent || 'cart',
    triggers: existingOffer && existingOffer.triggers || {
      conditions:{
        all:[
          {
            any: [
              {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
            ]
          }
        ]
      },
      event: {
        type: 'foundout',
        params: {
          message: 'rule has found out!'
        }
      }
    },
    shopBrain: existingOffer && existingOffer.shopBrain || null,
    aiConditions: existingOffer && existingOffer.aiConditions || {
      customerTags: [],
      products: []
    },
  }
  const [state, setState] = useState(initialEditingState)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [productPickerShow, setProductPickerShow] = useState('product')
  const [error, setError] = useState(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [productEdit, setProductEdit] = useState(-1)
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc
  );
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTabIndex(selectedTabIndex),
    [],
  )

  const [checkoutOfferType, setCheckoutOfferType] = useState(state.shopBrain === null ? 'manual':'ai');

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const handleCheckoutOfferTypeChange = (_checked, newValue) => {
    setCheckoutOfferType(newValue)

    if("ai" === newValue){
      if(state.shopBrain === null){
        if(existingOffer && existingOffer.shopBrain){
          setState(update(state, { shopBrain: { $set: existingOffer.shopBrain }}))
        }else{
          setState(update(state, { shopBrain: { $set: { exclusion:[{setting: 'tags',value: null}]} }}))
        }
      }
    }else{
      setState(update(state, { shopBrain: { $set: null }}))
    }

  }

  const tabs =
    [
      {
        id: 'top-bar-1',
        content: 'Top bar',
        panelID: 'top-bar-content-1',
      },
      {
        id: 'body-1',
        content: 'Body',
        panelID: 'body-content-1',
      },
      {
        id: 'buttons-1',
        content: 'Buttons',
        panelID: 'buttons-content-1',
      },
    ];

  let currentState = Object.assign({},state);
  let initialStateForCheck = Object.assign({},initialEditingState);
  delete currentState.enabled;
  delete initialStateForCheck.enabled;
  const hasUnsavedChanges = isEditing && !equal(currentState, initialStateForCheck)

  const handleRuleDelete = async () => {
    const newRules = await deleteRule(state.id, token, shop, firebase);
    try{
      if(newRules.find(rule => "Cart" === rule.offerType) === undefined){
        await removeCartScript({}, token, shop, host);
      }
    }
    catch (e) {
      console.log('Error creating rule: ', e)
    }

    navigate('/app/offers', {
      state: {
        ...location.state,
        tabIndex: Constants.TAB_INDEX.OFFER_LIST
      },
      replace: true,
    })
  }
  const handleFormSubmit = async (type) => {

    if (isSubmitting) {
      return
    }
    if(state.product === null && state.shopBrain === null ){
      setError({product: "Please select a product"})
      return
    }else{
      if(error != null){
        setError(null)
      }
    }
    setIsSubmitting(true)

    try {
      if (isEditing) {
        // TODO
        console.log('update rule.')
        await updateRule({
          ...state /* all rule data */,
          triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
          type: state.discount > 0 ? 'discount' : 'regular', // TODO handle product_discount eventually
        }, token, shop, firebase);

        navigate(
          `/app/offers/cart/v1/createCart`,
          {
            state: {
              rule: {...state, updatedAt: new Date().toISOString()}
            },
            replace: true,
          }
        )
      } else {

        const result = await createRule({
          ...state /* all rule data */,
          triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
          enabled: "save" === type ? false : true,
        }, token, shop, host)
        navigate(
          `/app/offers/cartOfferPopupSuccess`,
          {
            state: {
              rule: result.data.rules.find(rule => rule.id === result.data.id),
            },
            replace: true,
          }
        )
        console.log('result from creating rule: ', result)
      }

    }
    catch (e) {
      console.log('Error creating rule: ', e)
    }

    setIsSubmitting(false)
  }

  const addSmartRule =  () => {
    const conditions = {
      conditions:{
        all:[
          {
            any: [
              {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
            ]
          }
        ]
      },
      event: {
        type: 'foundout',
        params: {
          message: 'rule has found out!'
        }
      }
    }
    setState(update(state, { triggers: { $set: conditions }}))
  }

  const currency = (shopData && shopData.shopData && shopData.shopData.currency) ? shopData.shopData.currency : 'USD';
  const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
  return (
            <PageHeader 
              title={initialEditingState.name} 
              location={location}
              link={"/app/offers"} 
              existingOffer={existingOffer}
              token={token} 
              shop={shop} 
              firebase={firebase} 
              state={state} 
              setState={setState} 
              hasUnsavedChanges={hasUnsavedChanges} 
              handleFormSubmit={handleFormSubmit}
              isEditing={isEditing}
              isSubmitting={isSubmitting}
              windowDimensions={windowDimensions}
              >
      <Layout>
        <Layout.Section>
          {/*!existingOffer && (!rules || (rules && Array.isArray(rules) && rules.filter( rule => "Cart" === rule.offerType ).length === 0)) ?
            <div style={{margin: '20px 0 10px -25px'}}>
              <CreateOfferSteps step={2}/>
            </div>
            :
            false
          */}
          <div style={{ marginBottom: 50, }}>
            <Form
              disabled={
                !state.product ||
                !state.name ||
                !state.title
              }
              onSubmit={handleFormSubmit}>
              <CartOfferType host={host} token={token} checkoutOfferType={checkoutOfferType} handleCheckoutOfferTypeChange={handleCheckoutOfferTypeChange} shop={shop} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol}/>

              <LegacyCard sectioned>
                <Text variant="headingSm" as="h3">Customization</Text>

                <LegacyTabs tabs={tabs} selected={selectedTabIndex} onSelect={handleTabChange}>
                  <div style={{paddingTop: '20px'}}>
                    { selectedTabIndex === 0 &&
                    <TopBarTab state={state} setState={setState} checkoutOfferType={checkoutOfferType}/>
                    }
                    { selectedTabIndex === 1 &&
                    <BodyTab state={state} setState={setState} checkoutOfferType={checkoutOfferType} currency={currency} currencySymbol={currencySymbol}/>
                    }
                    { selectedTabIndex === 2 &&
                    <ButtonsTab state={state} setState={setState}/>
                    }
                  </div>
                </LegacyTabs>

              </LegacyCard>
              <div
                style={{
                  marginTop: 50,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row-reverse',
                  justifyContent: 'flex-start',
                }}
              >
                <div>
                  {
                    isCreating &&
                    <ButtonGroup>
                      <Button
                        onClick={ () => handleFormSubmit("save")}
                        variant="primary"
                        loading={isSubmitting}>
                        Save
                      </Button>
                      <Button submit variant="primary" loading={isSubmitting}>Save and publish</Button>
                    </ButtonGroup>
                  }
                  {
                    isEditing &&
                    <ButtonGroup>
                      <Button
                        tone="critical"
                        onClick={ () => { handleRuleDelete() } }>
                        Delete
                      </Button>
                      <Button
                        submit
                        variant="primary"
                        loading={isSubmitting}>
                        Save
                      </Button>
                    </ButtonGroup>
                  }
                </div>
                <div style={{ marginRight: 30 }}>
                  { isCreating ?
                    <Button
                      variant="plain"
                      tone = { (hasUnsavedChanges || isCreating) ? "critical" : "primary" }
                      onClick={() => {
                        navigate('/app/offers', {
                          state: {
                            ...location.state
                          },
                          replace: true,
                        })
                      }}
                    >
                      {
                        hasUnsavedChanges ?
                          'Discard Changes' :  'Discard'
                      }
                    </Button>
                    :
                    <Button
                      variant="plain"
                      tone = { (hasUnsavedChanges || isCreating) ? "critical" : "primary" }
                      onClick={() => {
                        navigate('/app/offers',
                          {
                            replace: true,
                          })
                      }}
                    >
                      {
                        hasUnsavedChanges ?
                          'Discard Changes' : 'Back'
                      }
                    </Button>
                  }
                </div>
              </div>
            </Form>
          </div>
        </Layout.Section>
        <Layout.Section variant={windowDimensions?.width < 1290 ? "" : "oneThird"}>
          <div
            style={windowDimensions?.width < 1290  ? {} : {
              position: 'fixed',
              width: 482, 
            }}
          >
            <CartPreview
              {...state}
              {...props}
              checkoutOfferType={checkoutOfferType}
              productEdit={productEdit}
              image={state.product && state.product.image}
              price={state.variants && state.variants[0].price}
              shopData={shopData}
            />
          </div>
        </Layout.Section>
      </Layout>
    </PageHeader>
  );
}

export default withFirebase(CreateCart);